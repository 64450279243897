import { DetailedHTMLProps, FormHTMLAttributes, FunctionComponent } from 'react';
import { useRouter } from 'next/router';
import { Box, Grid, Typography } from '@mui/material';

import useMobileDetect from '../../hooks/useMobileDetect';
import FormFooter from '../typography/FormFooter';

import FormButton from './FormButton';

interface FormBoxProps {
  backHidden?: boolean;
  /**
   * The URL to link to when the Back button is clicked.
   *  If defined, an `a` element will be used as the root node.
   */
  backHref?: string;
  onBackClick?: () => unknown | Promise<unknown>;
  footer?: string | JSX.Element;
  nextHidden?: boolean;
  backCaption?: string;
  nextCaption?: string;
  nextDisabled?: boolean;
  /**
   * The URL to link to when the Next button is clicked.
   *  If defined, an `a` element will be used as the root node.
   */
  nextHref?: string;
  onNextClick?: () => unknown | Promise<unknown>;
  subtitle?: string;
  title?: string;
  formProps?: DetailedHTMLProps<FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>;
  buttonLoading?: boolean;
  dataCy?: string;
  maxWidth?: number;
}

const FormBox: FunctionComponent<FormBoxProps> = ({
  backHidden = false,
  backHref,
  children,
  footer,
  nextHidden = false,
  backCaption = 'Back',
  nextCaption = 'Continue',
  nextDisabled = false,
  nextHref,
  onNextClick,
  onBackClick,
  subtitle,
  title,
  formProps,
  buttonLoading = false,
  dataCy = '',
  maxWidth = 412,
}) => {
  const router = useRouter();
  const isMobile = useMobileDetect('md');

  const handleNextClick = async () => {
    if (typeof onNextClick === 'function')
      await onNextClick();
    if (nextHref)
      router.push(nextHref);
  };

  const handleBackClick = async () => {
    if (typeof onBackClick === 'function')
      await onBackClick();
    if (backHref)
      router.push(backHref);
  };

  return (
    <Box data-cy={dataCy} display={'flex'} flexDirection={'column'} marginX={2}>
      {
        title && (
          <Typography variant='h1'>
            {title}
          </Typography>
        )
      }
      {
        subtitle && (
          <Typography variant='subtitle1'>
            {subtitle}
          </Typography>
        )
      }
      <Box
        display={'flex'}
        flexDirection={'column'}
        maxWidth={maxWidth}
        mx='auto'
        textAlign='left'
      >
        <form {...formProps}>
          {children}
        </form>
        <Grid alignContent='center' columnSpacing={isMobile ? 4 : 9} container flexDirection={'row'} justifyContent='center' mb={1} mt={5} px={isMobile ? 3 : 0}>
          {
            !backHidden
              ? (
                <Grid item xs={6}>
                  <FormButton
                    caption={backCaption}
                    dataCy='previous-button'
                    fullWidth={false}
                    marginTop={0}
                    nextHref={backHref}
                    onClick={handleBackClick}
                    variant='outlined'
                  >
                  </FormButton>
                </Grid>
              )
              : null
          }
          {
            !nextHidden && (
              <Grid item xs={backHidden ? 12 : 6}>
                <FormButton
                  caption={nextCaption}
                  dataCy='next-button'
                  disabled={nextDisabled}
                  fullWidth={false}
                  loading={buttonLoading}
                  marginTop={0}
                  nextHref={nextHref}
                  onClick={handleNextClick}
                >
                </FormButton>
              </Grid>
            )
          }
        </Grid>
        {
          !!footer && (
            <FormFooter>
              {footer}
            </FormFooter>
          )
        }
      </Box>
    </Box>
  );
};

export default FormBox;
