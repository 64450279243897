import { FunctionComponent, ReactElement } from 'react';
import { Box } from '@mui/material';

import StickyFooterLayout from '../layout/StickyFooterLayout';

import LoginCTA from './LoginCTA';
import Logo from './Logo';

interface LandingTemplateProps {
    withLoginCTA?: boolean;
}

const LandingTemplate: FunctionComponent<LandingTemplateProps> = ({
  children,
  withLoginCTA = true,
}) => (
  <StickyFooterLayout HeaderElement={withLoginCTA ? <LoginCTA dataCy='header-login-button' simple styleProps={{ justifyContent: 'end', px: 2, pt: 2 }} /> : undefined}>
    <Box display='flex' justifyContent='center' mt={4}>
      <Logo height={55} size='md' width={120} />
    </Box>
    <Box display='flex' flexDirection='column' paddingY={6} textAlign='center'>
      {children}
    </Box>
  </StickyFooterLayout>
);

export const getLandingTemplate = (page: ReactElement): ReactElement => (
  <LandingTemplate>{page}</LandingTemplate>
);

export const getLandingWithLoginCTATemplate = (page: ReactElement): ReactElement => (
  <LandingTemplate withLoginCTA>{page}</LandingTemplate>
);
