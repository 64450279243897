import { MouseEventHandler } from 'react';
import { Grid } from '@mui/material';
import Button, { ButtonProps } from '@mui/material/Button';
import Typography from '@mui/material/Typography';

interface InteractiveButtonWithAccordionTextProps {
  selected: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
  buttonText: string;
  accordionText: string;
  dataCy?: string;
}

const InteractiveButtonWithAccordionText = ({
  selected,
  onClick,
  buttonText,
  accordionText,
  dataCy,
}: InteractiveButtonWithAccordionTextProps): JSX.Element => {
  const buttonProps: ButtonProps = selected
    ? { color: 'primary', variant: 'contained' }
    : { variant: 'outlined' };

  return (
    <Grid item xs={12}>
      <Button
        data-cy={dataCy}
        onClick={onClick}
        {...buttonProps}
        fullWidth
      >
        {buttonText}
      </Button>
      {
        selected && (
          <Typography
            color='black'
            px={2}
            sx={{ marginTop: 2 }}
          >
            {accordionText}
          </Typography>
        )
      }
    </Grid>
  );
};

export default InteractiveButtonWithAccordionText;
