import { FunctionComponent } from 'react';
import { Grid, Link, Typography } from '@mui/material';

import { BRAND_COMPANY_NAME } from '../../constants/branding';
import routes from '../../lib/routes';

type StyleProps = {
  justifyContent?: string;
  pt?: number;
  px?: number;
};

type LoginCTAProps = {
  dataCy: string;
  opportunityReferral?: string;
  simple?: boolean;
  styleProps?: StyleProps;
};

const LoginCTA: FunctionComponent<LoginCTAProps> = ({
  dataCy,
  opportunityReferral,
  simple = false,
  styleProps = { justifyContent: 'end', px: 5, pt: 5 },
}) =>  {
  const loginUrl = opportunityReferral
    ? `${routes.internal.accountLogin.url}?opportunityReferral=${opportunityReferral}`
    : routes.internal.accountLogin.url;

  return (
    <Grid alignItems='baseline' container {...styleProps}>
      {
        !simple && (
          <Grid item>
            <Typography
              mr={1}
              style={{ color: 'textSecondary', overflow: '999' }}
              variant='subtitle2'
            >
              {`Already have a ${BRAND_COMPANY_NAME} account?`}
            </Typography>
          </Grid>
        )
      }
      <Grid item>
        <Link
          color='primary'
          data-cy={dataCy}
          href={loginUrl}
          underline='hover'
        >
          Login
        </Link>
      </Grid>
    </Grid>
  );
};

export default LoginCTA;

